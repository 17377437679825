html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
h1 {
    font-size: 2em;
}
/*
html {
    font-size: 16px;
}
@media screen and (min-width: 320px) {
    html {
        font-size: calc(16px + 8 * ((100vw - 320px) / 880));
    }
}
@media screen and (min-width: 1200px) {
    html {
        font-size: 24px;
    }
} */
/*
h1 {
    font-size: 2.0736rem;
}

@media screen and (min-width: 320px) {
    h1 {
        font-size: calc(27.648px + 26.352 * ((100vw - 320px) / 880));
    }
}
@media screen and (min-width: 1200px) {
    h1 {
        font-size: 54px;
    }
}
h2 {
    font-size: 1.728rem;
}
h2 {
    font-size: 27.648px;
}
@media screen and (min-width: 320px) {
    h2 {
        font-size: calc(27.648px + 26.352 * ((100vw - 320px) / 880));
    }
}
@media screen and (min-width: 1200px) {
    h2 {
        font-size: 54px;
    }
}
h3 {
    font-size: 1.44rem;
}
h3 {
    font-size: 23.04px;
}
@media screen and (min-width: 320px) {
    h3 {
        font-size: calc(23.04px + 12.96 * ((100vw - 320px) / 880));
        line-height: 30px;
    }
}
@media screen and (min-width: 1200px) {
    h3 {
        font-size: 36px;
    }
} */
