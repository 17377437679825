.FourZeroFour {
    position: relative;
    width: 100%;
    height: auto;
    background: #121212;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        mix-blend-mode: overlay;
        z-index: 0;
    }

    .code {
        font-size: 144px;
        height: 100vh;
        color: #ffffffb3;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-position: center;
        align-items: center;
        background-size: cover;
        justify-content: center;
        z-index: 1;
        .backHome {
            z-index: 1;
            font-size: 50px;
            div {
                color: whitesmoke;
                text-decoration: underline;
            }
        }
    }
}
