#chat-main {
    main {
        height: 50vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }
    main::-webkit-scrollbar {
        width: 0.25rem;
    }

    main::-webkit-scrollbar-track {
        background: #1e1e24;
    }

    main::-webkit-scrollbar-thumb {
        background: #6649b8;
    }

    form {
        height: 4vh;
        bottom: 0;
        background-color: rgb(24, 23, 23);
        width: 100%;
        max-width: 728px;
        display: flex;
        font-size: inherit;

        button {
            width: auto;
            background-color: rgb(56, 56, 143);
            color: white;
        }
    }
}

#chat-form {
    input {
        line-height: 1.5;
        width: 100%;
        font-size: 0.8rem;
        background: rgb(58, 58, 58);
        color: white;
        outline: none;
        border: none;
        padding: 0 10px;
    }
    button {
        background-color: #282c34; /* Green */
        border: none;
        color: white;
        padding: 0px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        font-size: 0.8rem;
    }
    button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    ul,
    li {
        text-align: left;
        list-style: none;
    }
}

// .sign-in {
//     color: #282c34;
//     background: white;
//     max-width: 400px;
//     margin: 0 auto;
// }

#chat-main {
    .ant-comment-content-author-name {
        color: white;
    }
}
.ant-comment-avatar {
    margin-left: 5px;
}

.ant-comment {
    margin: 5px;
    margin: 5px;
    border-radius: 5px;
}

.sent .ant-comment {
    color: white;
    background: #38398f;
}

.received .ant-comment {
    color: white;
    background: #002140;
}

.comment-action {
    padding-left: 8px;
    cursor: 'auto';
}

[class*='-col-rtl'] .comment-action {
    padding-right: 8px;
    padding-left: 0;
}

.ant-comment-actions {
    .anticon-like,
    .anticon-dislike,
    .comment-action {
        color: rgba(255, 255, 255, 0.65);
    }
}

#popover-caret {
    background: #011529;
    .ant-popover-inner-content {
        padding: 5px 7px;
        font-size: 12px;
    }
}

.ant-popover-arrow {
    border-right-color: #011529 !important;
    border-bottom-color: #011529 !important;
}
